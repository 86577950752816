import React, { useEffect } from 'react'
import CountdownTimer from './CountdownTimer'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import Layout from '../../../shared/UI/Layout';
import Loading from '../../../../vista/components/Loading';
import { getParentPath } from '../../../../vista/rutasPublicas/rutasPrivadasMy/entrenamiento/utils/UtilsEntrenamiento';

function CountDownTimerScreen() {
  const location = useLocation();
  const [initialTime, setInitialTime] = React.useState(0);
  const [timeLeft, setTimeLeft] = React.useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const { search } = location;
    const queryParams = new URLSearchParams(search);
    const time = parseInt(queryParams.get('initialTime') || '1', 10);
    setInitialTime(time);
    setTimeLeft(time * 60);
  }, [location]);
  const finishEarly = () => {
    const elapsedTime = initialTime * 60 - timeLeft;
    const parentPathName = getParentPath(location.pathname);
    const route = `${parentPathName}/finishReading?elapsedTime=${elapsedTime}`;
    navigate(route);
  };
  if (initialTime === 0) {
    return (
      <Layout title='Temporizador'>
        <Loading isOpen />
      </Layout>
    )
  }
  return (
    <div id='page'>
      <div id='toolbar_default'>
        <h1 className="title">Temporizador</h1>
      </div>
      <div id='content_default' style={{ padding: '10.5% 5%', height: '100svh', gap: '5%' }}>
        <CountdownTimer initialTime={initialTime * 60} timeLeft={timeLeft} setTimeLeft={setTimeLeft} />

        <button id='button_rosado_default' style={{ width: '80%', height: '7vh', margin: '5%' }} onClick={finishEarly}>Terminar Antes</button>
      </div>
    </div>

  )
}

export default CountDownTimerScreen