import { DocumentSnapshot } from 'firebase/firestore';
import { EncuentraMenorARegistro, useGenerarSiguienteRegistro } from '../utils/UtilsEntrenamiento';
import { nomUsuarios, nomUsuarioEntrenamiento, nomUsuarioEntrenamientoSesiones, nomEntrenamiento, nomRegistroEntrenamiento, nomRegistroActividad, nomUsuarioEntrenamientoActividades, nomEntrenamientoSesiones, nomEntrenamientoActividades } from '../../../../../datos/nombresBD';
import { useBaseDeDatos } from '../../../../../generalUtils/repositoryUtils/useBaseDeDatos';
import { Actividad, toActividad, toActividadParaVideo } from '../../../../../modelos/entrenamiento/Actividad';
import { toRegistroEntrenamiento, RegistroEntrenamiento, toFormatoFirebaseEntrenamiento } from '../../../../../modelos/entrenamiento/RegistroEntrenamiento';
import { RegistroActividad, toRegistroActividad } from '../../../../../modelos/entrenamiento/RegitroActividad';
import { Sesion } from '../../../../../modelos/entrenamiento/Sesion';
import { ChangeRedux_current_registro_usuario, ChangeRedux_registros_usuario_push, ChangeRedux_finishSesion } from '../../../../../redux/entrenamiento/reducerEntrenamiento';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { CallSendCallMessage, CallMessageData } from '../../../../utils/SendMessageCall';


export const useRegistroEntrenamiento = () => {

    /**
     * Funciones para acceder el base de datos
     * @type {Objecto}
     * @const
     */
    const {
        agregarDoc, 
        reemplazarDoc,
        ActualizarDoc,
        recogerDocs,
        recogerDocsList,
        recogerDoc,
    } = useBaseDeDatos();
    const dispatch = useAppDispatch()
    const redux_registroActual = useAppSelector((state) => state.entrenamiento?.current_registro_usuario)
    const getNuevoRegistro = useGenerarSiguienteRegistro()
    
    const generarRegistroSesion = async (userId: string,entrenamientoId:string,sesion:Sesion) => {    
        agregarDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioEntrenamiento + "/" + entrenamientoId + "/" + nomUsuarioEntrenamientoSesiones + "/" + sesion.id, sesion);
    }
    const primerIngresoActividad = async (userId: string,entrenamientoId:string,idRegistro:string) => {
        const doc:DocumentSnapshot  = await recogerDoc(nomEntrenamiento + "/" + entrenamientoId + "/" + nomRegistroEntrenamiento + "/" + userId + "/" + nomRegistroActividad + "/" + idRegistro)
        console.log("🚀 ~ file: useRegistroEntrenamiento.ts:32 ~ primerIngresoActividad ~ doc.exists():", doc.exists())
        if(doc.exists()){
            return false
        }else{
            return true
        }
    }

    const generarRegistroActividad = async (userId: string,entrenamientoId:string,actividad:Actividad,sesionId?:string) => {
        if(sesionId == null || sesionId == "null" || sesionId == undefined){
            agregarDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioEntrenamiento + "/" + entrenamientoId + "/" + nomUsuarioEntrenamientoActividades + "/" + actividad.id, actividad);
        }else{
            agregarDoc(nomUsuarios + "/" + userId + "/" + nomUsuarioEntrenamiento + "/" + entrenamientoId + "/" + nomUsuarioEntrenamientoSesiones + "/" + sesionId + "/" + nomUsuarioEntrenamientoActividades + "/" + actividad.id, actividad);
        }
    }
    
    const consultarRegistroEntrenamiento = async (userId: string,entrenamientoId:string) => {        
        const doc:DocumentSnapshot = await recogerDoc(nomEntrenamiento + "/" + entrenamientoId + "/" + nomRegistroEntrenamiento + "/" + userId);
        console.log("🚀 ~ consultarRegistroEntrenamiento ~ nomEntrenamiento", nomEntrenamiento + "/" + entrenamientoId + "/" + nomRegistroEntrenamiento + "/" + userId)
        if(doc.exists()){
            const registro = toRegistroEntrenamiento(doc)
            return registro
        }else{
            return null
        }
    }

    const consultarRegistros = async (userId: string, entrenamientosId: string[]) => {
        const rutas = entrenamientosId.map(id => (nomEntrenamiento + "/" + id + "/" + nomRegistroEntrenamiento + "/" + userId));
        const docs = await recogerDocsList(rutas);

        let registros: RegistroEntrenamiento[] = [];
        for(let i = 0; i < docs.length; i++){
            const doc = docs[i];
            if(doc.exists()){
                let registro = doc.data() as RegistroEntrenamiento;
                registro.idEntrenamiento = entrenamientosId[i];
                registros.push(registro);
            }
        }

        return registros;
    }

    const crearPrimerRegistroEntrenamiento = async (userId: string,entrenamientoId:string,newInitialRegistro?:RegistroEntrenamiento) => {
        if(!newInitialRegistro) return
        console.log("🚀 ~ crearPrimerRegistroEntrenamiento ~ newInitialRegistro:", newInitialRegistro)
        dispatch(ChangeRedux_current_registro_usuario({registro:newInitialRegistro}))
        dispatch(ChangeRedux_registros_usuario_push({registro:newInitialRegistro,entrenamientoID:entrenamientoId}))        
        let regToFirebase = toFormatoFirebaseEntrenamiento(newInitialRegistro)
        console.log("🚀 ~ crearPrimerRegistroEntrenamiento ~ regToFirebase:", regToFirebase)
        await agregarDoc(nomEntrenamiento + "/" + entrenamientoId + "/" + nomRegistroEntrenamiento + "/" + userId, regToFirebase);
        return newInitialRegistro
    }

    const modificarConfiguracion = async (userId: string,entrenamientoId:string,nuevoRegistro:RegistroEntrenamiento) => {        
        dispatch(ChangeRedux_current_registro_usuario({registro:nuevoRegistro}))
        let regToFirebase = toFormatoFirebaseEntrenamiento(nuevoRegistro)
        ActualizarDoc(nomEntrenamiento + "/" + entrenamientoId + "/" + nomRegistroEntrenamiento + "/" + userId, regToFirebase);
    }

    // Pasar orden de Sesion y Actividad en un index de 1-n no de 1-n
    const actualizarRegistroEntrenamiento = async (correoUsuario:string,userId: string,entrenamientoId:string,ordenSesion:number,ordenActividad:number):Promise<RegistroEntrenamiento|null> => {
        let num = EncuentraMenorARegistro(ordenSesion,ordenActividad,redux_registroActual)
        if( num === 0 || num == 2){
            let nuevoReg:RegistroEntrenamiento = await getNuevoRegistro
            let regToFirebase = toFormatoFirebaseEntrenamiento(nuevoReg)
            console.log("🚀 ~ actualizarRegistroEntrenamiento ~ regToFirebase:", regToFirebase)
            agregarDoc(nomEntrenamiento + "/" + entrenamientoId + "/" + nomRegistroEntrenamiento + "/" + userId,regToFirebase)
            dispatch(ChangeRedux_current_registro_usuario({registro:nuevoReg}))
            if(nuevoReg.ordenActividad === 0 || nuevoReg.completadoEntrenamiento){
                if(nuevoReg.completadoEntrenamiento === true){
                    const audiosopciones = [
                        "https://firebasestorage.googleapis.com/v0/b/supercerebros-produccion.appspot.com/o/Entrenamientos%2FAudiosEntrenamientos%2Ffinalizarcurso1.mp3?alt=media&amp;token=6529afab-d499-4e12-a8ea-b1c6cdd6c2c6",
                        "https://firebasestorage.googleapis.com/v0/b/supercerebros-produccion.appspot.com/o/Entrenamientos%2FAudiosEntrenamientos%2Ffinalizarcurso2.mp3?alt=media&amp;token=0e2385a2-df3d-4c84-81a5-97fac9506f2a",
                        "https://firebasestorage.googleapis.com/v0/b/supercerebros-produccion.appspot.com/o/Entrenamientos%2FAudiosEntrenamientos%2Ffinalizarcurso3.mp3?alt=media&amp;token=426f5d95-124f-462c-9e01-2952e4b9344e"
                    ]
                    console.log("🚀 ~ actualizarRegistroEntrenamiento ~ nuevoReg.telefonoCompleto:", nuevoReg.telefonoCompleto)
                    console.log("🚀 ~ actualizarRegistroEntrenamiento ~ nuevoReg.nombre:", nuevoReg.nombre)
                    CallSendCallMessage({
                        telefono: `${nuevoReg.telefonoCompleto}`,
                        idTemplateLlamada: 'HXa55054ebf22ebae6c08f20b2a6116b23',
                        idTemplateCorreo: 'd-14e3bff1edac4e4286ea29501d5df7ee',
                        variablesMensaje: {1:entrenamientoId},
                        variablesCorreo: {"nombre_curso":entrenamientoId,"nombre_user":nuevoReg.nombre},
                        realizarLlamada: true,
                        urlAudio: audiosopciones[Math.floor(Math.random() * 3)],
                        correo: correoUsuario

                    } as CallMessageData)
                }
                dispatch(ChangeRedux_finishSesion(ordenSesion-1))
            }
            return nuevoReg 
        }
        console.log("No se debe actualizar registro")
        return null
    }

    const crearRegistroActividad = async(userId:string, idEntrenamiento:string,registro:RegistroActividad) => {
        await agregarDoc(nomEntrenamiento + "/" + idEntrenamiento + "/" + nomRegistroEntrenamiento + "/" + userId + "/" + nomRegistroActividad + "/" + registro.id,registro)
    }

    const recogerRating = async(idEntrenamiento:string,idSesion:string,idActividad:string) => {
        let doc = await recogerDoc(nomEntrenamiento + "/" + idEntrenamiento + "/" + nomEntrenamientoSesiones + "/" + idSesion + "/" + nomEntrenamientoActividades + "/" + idActividad)
        const actividad = toActividad(doc);
        return actividad
    }

    const agregarRating = async(idEntrenamiento:string,idSesion:string,idActividad:string,registro:RegistroActividad) => {
        let actividad = await recogerRating(idEntrenamiento,idSesion,idActividad)
        console.log("🚀 ~ agregarRating ~ actividad:", actividad)
        let antiguoProm:number = actividad?.rating ?? 0;
        let prom = (antiguoProm * actividad?.cantidadPersonas + registro.like)/(actividad?.cantidadPersonas +1)
        let copy = actividad
        copy.rating = prom ?? 0
        copy.cantidadPersonas = Number(actividad.cantidadPersonas ?? 0) + 1;
        let registroAFirebase = toActividadParaVideo(copy)
        console.log("🚀 ~ agregarRating ~ copy:", copy)
        console.log("🚀 ~ agregarRating ~ registroAFirebase:", registroAFirebase)
        await agregarDoc(nomEntrenamiento+ "/" + idEntrenamiento + "/" + nomEntrenamientoSesiones + "/" + idSesion + "/" + nomEntrenamientoActividades + "/" + registroAFirebase.id,registroAFirebase)
    }

    const actualizarRating = async(idEntrenamiento:string,idSesion:string,idActividad:string,registro:RegistroActividad,antiguoLike:number) => {
        let actividad = await recogerRating(idEntrenamiento,idSesion,idActividad)
        let antiguoProm:number = actividad?.rating ?? 0
        antiguoProm = isNaN(antiguoProm) || Math.abs(antiguoProm) === Infinity ? 0 : antiguoProm
        let totalSinAnterior = (antiguoProm*actividad?.cantidadPersonas - antiguoLike)
        totalSinAnterior = totalSinAnterior < 0 ? 0 : totalSinAnterior
        let prom = (totalSinAnterior + registro.like)/(actividad?.cantidadPersonas ?? 1)
        let copy = actividad
        copy.rating = (isNaN(antiguoProm) || Math.abs(antiguoProm) === Infinity) ? 0 : prom
        let registroAFirebase = toActividadParaVideo(copy)
        await agregarDoc(nomEntrenamiento+ "/" + idEntrenamiento + "/" + nomEntrenamientoSesiones + "/" + idSesion + "/" + nomEntrenamientoActividades + "/" + registroAFirebase.id,registroAFirebase)
    }
    
    const consultarLike = async(userId:string, idEntrenamiento:string,idRegistro:string) => {
        const doc:DocumentSnapshot  = await recogerDoc(nomEntrenamiento + "/" + idEntrenamiento + "/" + nomRegistroEntrenamiento + "/" + userId + "/" + nomRegistroActividad + "/" + idRegistro)
        if(doc.exists()){
            const registro = toRegistroActividad(doc)
            return registro
        }else{
            return null
        }
    }
    return {        
        actualizarRegistroEntrenamiento, 
        modificarConfiguracion,
        consultarRegistroEntrenamiento,
        consultarRegistros,
        crearPrimerRegistroEntrenamiento,
        generarRegistroSesion,
        generarRegistroActividad,
        crearRegistroActividad,
        consultarLike,
        agregarRating,
        actualizarRating,
        primerIngresoActividad,
    }
}

export default useRegistroEntrenamiento;