import '../../../../../../theme/entrenamiento/entrenamiento.css';
import stylePrograma from "../../../../../../theme/admin/programa.module.css";
import styleVistaPrograma from "../../../../../../theme/entrenamiento/componentes/VistaPrograma.module.css";
import estiloJuegos from "../../../../../../theme/Juegos.module.css";

import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDato } from '../../../../../../modelos/auth';
import { Actividad } from '../../../../../../modelos/entrenamiento/Actividad';
import { changeCondicion, addParametroBloqueo, addTipoPregunta } from '../../../../../../redux/actividad/reducerActividad';
import { sumaNeuronas } from '../../../../../../redux/auth/reducerAuth';
import { useAppSelector, useAppDispatch } from '../../../../../../redux/store';
import useNeurona from '../../../../../../repositorio/controlador/useNeurona';
import PopoverNeuronas from '../../../../../components/PopoverNeuronas';



interface Props {
  handleBack: () => Promise<void>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  urlRedirect: string;
  actividad: Actividad;
  firstTime: (0 | 1 | 2);
  GuardarRegistro: () => Promise<void>;
}


const VistaDePrograma: React.FC<Props> = (props) => {
  const userId = useAppSelector((state) => state.auth.userId);
  const confirmacion = useAppSelector((state) => state.activity.confirmacion);
  const { actualizarNeuronas } = useNeurona();
  const { state } = useDato();
  const [showNeuronas, setShowNeuronas] = useState<boolean>(false);
  const [neuronasGanadas, setNeuronasGanadas] = useState<number>(0);
  const [mensaje, setMensaje] = useState<string>('¡Completa el video la próxima vez para maximizar tus ganancias!');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      setShowNeuronas(false)
    }
  }, [])

  useEffect(() => {
    if (confirmacion !== 0) {
      dispatch(changeCondicion({ condicion: 0 }));
    }
  }, [confirmacion])

  const HandleClickPrograma = async () => {
    if (props.firstTime === (0 | 2)) {
      dispatch(addParametroBloqueo({ tiene_bloqueo: Number(props.actividad.tiene_bloqueo) }))
    } else {
      dispatch(addParametroBloqueo({ tiene_bloqueo: 0 }))
    }
    dispatch(addTipoPregunta({ tipoPregunta: props.actividad.tipoPregunta }))
    dispatch(changeCondicion({ condicion: 0 }))
    if (typeof (props.actividad?.ruta) === 'string') { navigate(props.actividad?.ruta) };
  }

  const guardarNeuronas = () => {
    dispatch(sumaNeuronas({ cantidad: neuronasGanadas }))
    actualizarNeuronas(userId, neuronasGanadas, state._usuarioDato.nombres)
    return (
      <></>
    )
  }

  useEffect(() => {
    if (showNeuronas == true) {
      guardarNeuronas()
    }
  }, [showNeuronas])

  // const configurarMensaje = () => {
  //   if(props.firstTime === (0|2)){
  //     setMensaje(`¡Felicidades! Has ganado ${confirmacion*10} neuronas por tu compromiso con el entrenamiento. ¡Sigue así!`)
  //   }
  // }

  // const mostrarNeuronas = async() => {
  //   if(props.firstTime == (0|2)){
  //     setNeuronasGanadas(confirmacion*10)
  //     configurarMensaje()
  //     setShowNeuronas(true)
  //   }else{
  //     FunctionSalir()
  //   }
  // }

  const FunctionSalir = async () => {
    setShowNeuronas(false);
    return props.handleBack();
  }

  return (
    <>
      <div className={`${estiloJuegos.toolbarCalculoFecha} ${styleVistaPrograma.bannerPrograma}`}>
        <div style={{ display: 'flex' }}>
          <button className={`${stylePrograma.backButton} ${styleVistaPrograma.backButton}`} onClick={FunctionSalir} />
          <h1 className={`${styleVistaPrograma.title} ${estiloJuegos.tituloJuegos}`} > {props.actividad?.titulo} </h1>
        </div>
      </div>
      <div className={`${estiloJuegos.contenedorIconoFecha} ${styleVistaPrograma.contenedorPrograma}`}>
        <img src={'/assets/icon/icon_neuronas.svg'} className={styleVistaPrograma.programaIcono}></img>
      </div>
      <div className={stylePrograma.content}>
        <div className={estiloJuegos.primeraPantalla}>
          {props.firstTime !== 1 ? <div className={estiloJuegos.descripcionJuego}>
            {props.actividad ? props.actividad.descripcion : ""}
          </div> : <div className={estiloJuegos.descripcionJuego}>Felicidades ya completaste esta actividad</div>}
          <button className={`${estiloJuegos.buttonEmpezar} ${styleVistaPrograma.buttonEmpezar}`} onClick={HandleClickPrograma}>
            {'EMPEZAR'}
          </button>
          <button onClick={FunctionSalir} className={styleVistaPrograma.buttonVolver}>
            volver
          </button>
        </div>
      </div>
      {showNeuronas &&
        <PopoverNeuronas nuevasNeuronas={neuronasGanadas} mensajeInfo={mensaje} nivel={neuronasGanadas} funcionVolver={() => { FunctionSalir() }} esEntrenamiento={true} />
      }
    </>
  )
}

export default VistaDePrograma
