import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: "user",
};
type CammeraProps = {
    onFinish: (imgSrc: string) => void
}
const Cammera = ({ onFinish }: CammeraProps) => {
    const webcamRef = useRef<Webcam>(null);
    const [imgSrc, setImgSrc] = useState<string>(null);

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
    }, [webcamRef, setImgSrc]);


    return (
        <>
            {!imgSrc &&
                <React.Fragment>

                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        minScreenshotWidth={180}
                        minScreenshotHeight={180}
                    />
                    <button id='button_rosado_default' onClick={capture}>Capture Photo</button>
                </React.Fragment>
            }
            {imgSrc &&
                <React.Fragment>
                    <img src={imgSrc} alt="img" />
                    <button id='button_rosado_default' onClick={() => onFinish(imgSrc)}>Hecho 🚀🚀🚀</button>
                    <button id='button_rosado_default' onClick={() => setImgSrc(null)}>Tomar Otra vez 📷📷📷 </button>
                </React.Fragment>
            }
        </>
    );
};

export default Cammera