import React, { useEffect, useRef } from 'react';
import './Stopwatch.css';

type StopwatchProps = {
  time: number;
  setTime: React.Dispatch<React.SetStateAction<number>>;
};

function Stopwatch({ time, setTime }: StopwatchProps) {
  const startTimeRef = useRef<number | null>(null);

  useEffect(() => {
    if (!startTimeRef.current) {
      startTimeRef.current = Date.now() - time * 1000;
    }

    const updateTimer = () => {
      setTime(Math.floor((Date.now() - (startTimeRef.current || Date.now())) / 1000));
    };

    updateTimer();
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [setTime]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };

  return (
    <div className="stopwatch">
      <div className="stopwatch__display">{formatTime(time)}</div>
    </div>
  );
}

export default Stopwatch;
