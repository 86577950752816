import React, { useCallback } from 'react'
import { useBaseDeDatos } from '../../../generalUtils/repositoryUtils/useBaseDeDatos'
import { nomLecturas, nomUsuarios } from '../../../datos/nombresBD';
import { DocumentData } from 'firebase/firestore';
import { useAppSelector } from '../../../redux/store';

function useReadingExcercise(): [
    () => Promise<DocumentData[]>,
    (readingId: string) => Promise<DocumentData>,
    (recordData: any) => Promise<string>,
] {
    const [userId] = useAppSelector((state) => [state.auth.userId]);
    const { recogerCollection, recogerDoc, agregarCollection, ActualizarDoc } = useBaseDeDatos();
    const getReadings = useCallback(
        async () => {
            const readings = await recogerCollection(`${nomLecturas}`)
            return readings
        },
        [recogerCollection],
    )
    const getReadingById = useCallback(
        async (readingId: string) => {
            const reading = await recogerDoc(`${nomLecturas}/${readingId}`)
            return reading.data();
        }
        , [])
    const addReadingRecord = useCallback(async (recordData: any) => {
        const route = `${nomUsuarios}/${userId}/mi_registro_de_lectura`;
        const newDocumentId = await agregarCollection(route, recordData);
        await ActualizarDoc(`${nomUsuarios}/${userId}/mi_registro_de_lectura/${newDocumentId}`, { id: newDocumentId });
        return newDocumentId;
    }, [ActualizarDoc, agregarCollection, userId]);

    return [
        getReadings,
        getReadingById,
        addReadingRecord,
    ]
}

export default useReadingExcercise