import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useReadingExcercise } from '../../hooks';
import Layout from '../../../shared/UI/Layout';
import Loading from '../../../../vista/components/Loading';
import QuestionsScreen from './Questions.screen';
import ReadingScreen from './Reading.screen';
import ResultScreen from './Result.screen';
function InnerReadingExcerciseScreen() {
    const location = useLocation();
    const navigate = useNavigate();
    const { register, handleSubmit, watch } = useForm();
    const [getReadings] = useReadingExcercise();
    const [levels, setLevels] = useState([]);
    const [filteredReadings, setFilteredReadings] = useState([]);
    useEffect(() => {
        const calculateLevels = async () => {
            const readings = await getReadings();
            if (readings.length) {
                const levels = readings.map(obj => obj.nivel);
                const uniqueLevels = Array.from(new Set(levels))
                setLevels(uniqueLevels)
            }

        };
        calculateLevels();
    }, []);
    const onSubmit = (data: any) => {
        navigate(`${location.pathname}/Reading?readingId=${data.reading_id}`)
    }
    const onChangeLevel = async (e) => {
        const readings = await getReadings();
        const level = parseInt(e.target.value);
        const _filteredReadings = readings.filter(reading =>
            reading.nivel + "" === level + ""
        )
        setFilteredReadings(_filteredReadings)
    }
    const wasReadingSet = watch("reading_id")
    if (levels.length === 0) {
        return (
            <Layout title='Ejercicio Lectura'>
                <Loading isOpen />
            </Layout>
        )
    }
    return (
        <Layout title="Ejercicio lectura">
            <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <div className='item-contraseña'>
                    <label >Nivel</label>
                    <select
                        {...register("level_id",
                            {
                                onChange: onChangeLevel
                            }
                        )}
                    >
                        <option value={""}>Seleccione el nivel</option>
                        {
                            levels.map((level, index) => (
                                <option key={`level_${index}`} value={level}>{level}</option>
                            ))
                        }
                    </select>
                </div>
                {
                    !!filteredReadings.length &&
                    <div className='item-contraseña'>
                        <label >Lectura</label>
                        <select
                            {...register("reading_id")}
                        >
                            <option value={""}>Seleccione una Lectura</option>
                            {
                                filteredReadings.map((reading, index) => (
                                    <option key={`reading_${index}`} value={reading.id}>{reading.titulo}</option>
                                ))
                            }
                        </select>
                    </div>
                }
                {
                    !!wasReadingSet &&
                    <>
                        <p style={{ margin: 20 }}>Prepárate para leer la lectura</p>
                        <input type='submit' id='button_rosado_default' style={{ width: '80%', height: '7vh', margin: '5%' }} value={"Inicia la lectura"} />
                    </>
                }
            </form>
        </Layout>
    )
}
function ReadingExcerciseScreen() {
    return (
        <Routes>
            {/* Add routes here */}
            <Route path="/" element={<InnerReadingExcerciseScreen />} />
            <Route path="/Reading" element={<ReadingScreen />} />
            <Route path="/Questions" element={<QuestionsScreen />} />
            <Route path="/Result" element={<ResultScreen />} />
        </Routes>
    )
}

export default ReadingExcerciseScreen