import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import InitialConfigScreen from './InitialConfig.screen'
import { useReadingToday } from '../../hooks';
import GoToRead from '../components/GoToRead';
import Loading from '../../../../vista/components/Loading';
import Layout from '../../../shared/UI/Layout';
import CountDownTimerScreen from '../components/CountDownTimer.screen';
import StopWatchScreen from '../components/Stopwatch.screen';
import ReadingTodayCheckInformation from '../components/ReadingTodayCheckInformation';
import FinishReadingScreen from '../components/FinishReading.screen';
import SummaryScreen from '../components/Summary.screen';


function ReadingTodayScreen() {

    return (
        <Routes>
            {/* Add routes here */}
            <Route path="/" element={<ReadingTodayCheckInformation />} />
            <Route path='/initialConfig' element={<InitialConfigScreen />} />
            <Route path='/goToRead' element={<GoToRead />} />
            <Route path="/countDownTimer" element={<CountDownTimerScreen />} />
            <Route path="/stopWatch" element={<StopWatchScreen />} />
            <Route path="/finishReading" element={<FinishReadingScreen />} />
            <Route path="/summary" element={<SummaryScreen />} />

        </Routes>
    )
}

export default ReadingTodayScreen