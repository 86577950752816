import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useReadingToday } from '../../hooks';
import Layout from '../../../shared/UI/Layout';
import Loading from '../../../../vista/components/Loading';
import { getParentPath } from '../../../../vista/rutasPublicas/rutasPrivadasMy/entrenamiento/utils/UtilsEntrenamiento';
import estiloJuegos from "../../../../theme/Juegos.module.css";


function GoToRead() {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentBook, setCurrentBook] = useState<any>(null);
  const [myBooks] = useReadingToday();
  const goToCountDownTimer = () => {

    const route = `${getParentPath(location.pathname)}/countDownTimer?initialTime=${currentBook.tiempo_lectura}`;
    navigate(route);
  }
  const gotToStopWatch = () => {
    const route = `${getParentPath(location.pathname)}/stopWatch`;
    navigate(route);
  }
  useEffect(() => {
    const getMyBooks = async () => {
      const books = await myBooks();
      const currentBook = books.find((book: any) => !book.fue_el_libro_leído);
      setCurrentBook(currentBook);
    }
    getMyBooks();
  }, [myBooks])
  if (!currentBook) {
    return (
      <Layout title='Vamos a leer'>
        <Loading isOpen />
      </Layout>
    )
  }
  const goToInitialConfig = () => {
    const route = `${getParentPath(location.pathname)}/initialConfig`;
    navigate(route);
  }
  return (
    <div id='page'>
      <div id='toolbar_default'>
        <button className={estiloJuegos.backJuegos} onClick={goToInitialConfig} />
        <h1 className="title">Vamos a Leer</h1>
      </div>
      <div id='content_default' style={{ padding: '10.5% 5%', height: '100svh', gap: '5%' }}>
        <p> Vamos a leer : {currentBook.nombre_libro}</p>
        <button id='button_rosado_default' style={{ width: '80%', height: '7vh', margin: '5%' }} onClick={goToCountDownTimer}>{currentBook.tiempo_lectura} minutos</button>
        <button id='button_rosado_default' style={{ width: '80%', height: '7vh', margin: '5%' }} onClick={gotToStopWatch}>Tiempo libre</button>
      </div>
    </div>
  )
}

export default GoToRead