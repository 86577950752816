import React, { useCallback, useEffect, useState } from 'react';
import './CountdownTimer.css';
import useSound from 'use-sound';
const FULL_DASH_ARRAY = 283;
const WARNING_THRESHOLD = 10;
const ALERT_THRESHOLD = 5;
const COLOR_CODES = {
  info: { color: "green" },
  warning: { color: "orange", threshold: WARNING_THRESHOLD },
  alert: { color: "red", threshold: ALERT_THRESHOLD },
};

type CountdownTimerProps = {
  initialTime: number;
  timeLeft: number;
  setTimeLeft: React.Dispatch<React.SetStateAction<number>>;
};

function CountdownTimer({ initialTime, timeLeft, setTimeLeft }: CountdownTimerProps) {
  const [remainingPathColor, setRemainingPathColorState] = useState(COLOR_CODES.info.color);
  const [startTime, setStartTime] = useState(Date.now());
  const [playAlarm] = useSound('/audio/campana.mp3');
  const [alarmSound] = useState(new Audio("/audio/campana.mp3"));
  const [isAlarmActived, setIsAlarmActived] = useState(false);
  useEffect(() => {
    setStartTime(Date.now());

    const timerInterval = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
      const newTimeLeft = Math.max(initialTime - elapsedTime, 0);

      setTimeLeft(newTimeLeft);

      if (newTimeLeft === 0) {
        clearInterval(timerInterval);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);
  const playSound = useCallback(() => {
    // 🔊 Asegurar que se puede reproducir el sonido después de interacción del usuario
    alarmSound.play()
      .then(() => { console.log("alarma sonó") })
      .catch((error) => { console.error("error reproduciendo sonido", error) })
    playAlarm()
  }, [playAlarm, alarmSound])
  useEffect(() => {
    setCircleDasharray();
    updateRemainingPathColor(timeLeft);
    // 🔊 Reproducir sonido cuando el tiempo llega a 0
    if (timeLeft === 0) {
      playSound();
    }
  }, [playSound, setCircleDasharray, timeLeft]);

  function formatTime(time: number) {
    const minutes = Math.floor(time / 60);
    let seconds: string | number = time % 60;
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }

  function updateRemainingPathColor(timeLeft: number) {
    if (timeLeft <= COLOR_CODES.alert.threshold) {
      setRemainingPathColorState(COLOR_CODES.alert.color);
    } else if (timeLeft <= COLOR_CODES.warning.threshold) {
      setRemainingPathColorState(COLOR_CODES.warning.color);
    } else {
      setRemainingPathColorState(COLOR_CODES.info.color);
    }
  }

  function calculateTimeFraction() {
    const rawTimeFraction = timeLeft / initialTime;
    return rawTimeFraction - (1 / initialTime) * (1 - rawTimeFraction);
  }

  function setCircleDasharray() {
    const circleDasharray = `${(calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0)} 283`;
    document.getElementById("base-timer-path-remaining")?.setAttribute("stroke-dasharray", circleDasharray);
  }

  return (
    <React.Fragment>
      <div className="base-timer">
        <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <g className="base-timer__circle">
            <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
            <path
              id="base-timer-path-remaining"
              strokeDasharray="283"
              className={`base-timer__path-remaining ${remainingPathColor}`}
              d="M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0"
            ></path>
          </g>
        </svg>
        <span id="base-timer-label" className="base-timer__label">{formatTime(timeLeft)}</span>
      </div>
      {
        !isAlarmActived &&
        <button id='button_rosado_default' style={{ width: '80%', height: '7vh', margin: '5%' }} onClick={() => {
          playSound();
          setIsAlarmActived(true)
        }}>Activar el recordatorio 🔔🔔🔔</button>
      }

    </React.Fragment>
  );
}

export default CountdownTimer;
