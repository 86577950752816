import React, { useEffect, useState } from 'react'
import Stopwatch from './Stopwatch'
import Layout from '../../../shared/UI/Layout';
import Loading from '../../../../vista/components/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { getParentPath } from '../../../../vista/rutasPublicas/rutasPrivadasMy/entrenamiento/utils/UtilsEntrenamiento';

function StopWatchScreen() {
  const [initialTime, setInitialTime] = React.useState(0);
  const [time, setTime] = useState(-1);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const { search } = location;
    const queryParams = new URLSearchParams(search);
    const time = parseInt(queryParams.get('initialTime') || '0', 10);
    setInitialTime(time);
    setTime(time);
  }, [location]);
  const finish = () => {
    const parentPathName = getParentPath(location.pathname);
    const route = `${parentPathName}/finishReading?elapsedTime=${time}`;
    navigate(route);
  }
  if (time < 0) {
    return (
      <Layout title='Cronómetro'>
        <Loading isOpen />
      </Layout>
    )
  }
  return (
    <div id='page'>
      <div id='toolbar_default'>
        <h1 className="title">Cronómetro</h1>
      </div>
      <div id='content_default' style={{ padding: '10.5% 5%', height: '100svh', gap: '5%' }}>
        <Stopwatch time={time} setTime={setTime} />
        <button id='button_rosado_default' style={{ width: '80%', height: '7vh', margin: '5%' }} onClick={finish}>Finalizar</button>
      </div>
    </div>

  )
}

export default StopWatchScreen