import React from 'react'
import { useForm } from 'react-hook-form';
type InitialConfigFormProps = {
    onSubmit: (data: any) => Promise<void>
    defaultValues: any
}
function InitialConfigForm({ onSubmit, defaultValues }: InitialConfigFormProps) {
    const { register, handleSubmit, reset, formState: {
        errors,
        isSubmitting
    } } = useForm({
        defaultValues
    });
    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <div className='item-contraseña'>
                <label >Título</label>
                <input
                    className={errors.nombre_libro ? 'error' : ''}
                    {...register('nombre_libro', { required: true })}
                />
            </div>
            {errors.nombre_libro && <p role="alert">{`${errors.nombre_libro?.message}`}</p>}
            <div className='item-contraseña'>
                <label >Autor</label>
                <input
                    {...register('autor_libro', { required: true })}
                />
            </div>
            <div className='item-contraseña'>
                <label >Cantidad Páginas</label>
                <input
                    inputMode='numeric'
                    type="number"
                    {...register('páginas', { required: true })}
                />
            </div>
            <div className='item-contraseña'>
                <label >¿A qué horas vas a leer?</label>
                <input
                    type="time"
                    {...register('hora_lectura', { required: true })}
                />
            </div>

            <div className='item-contraseña'>
                <label >¿Cuánto tiempo vas a leer? (minutos)</label>
                <input
                    inputMode='numeric'
                    type="number"
                    {...register('tiempo_lectura', { required: true })}
                />
            </div>
            <input type='submit'
                id='button_rosado_default'
                style={{ width: '80%', height: '7vh', margin: '5%' }}
                value={isSubmitting ? "Guardando....." : defaultValues ? "Actualizar" : "Guardar"}
                disabled={isSubmitting} />
        </form>
    )
}

export default InitialConfigForm