import React, { useEffect, useState } from 'react'
import styleVistaVideo from "../../../../theme/entrenamiento/componentes/VistaVideo.module.css";
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../../shared/UI/Layout';
import Loading from '../../../../vista/components/Loading';
import { getParentPath } from '../../../../vista/rutasPublicas/rutasPrivadasMy/entrenamiento/utils/UtilsEntrenamiento';
import { useForm } from 'react-hook-form';
import { useReadingToday } from '../../hooks';
type FinishReading1Props = {
  elapsedTime: number;
  readMore: () => void;
  finish: () => void;
}
function FinishReading1({ elapsedTime, readMore, finish }: FinishReading1Props) {
  return (
    <React.Fragment>
      <p className={styleVistaVideo.descripcion}>
        Felicitaciones ya has leido hasta el momento {Math.ceil(elapsedTime / 60)} minutos.
      </p>
      <p className={styleVistaVideo.descripcion}>
        ¿Deseas leer un poco más?
      </p>
      <button id='button_rosado_default' style={{ width: '80%', height: '7vh', margin: '5%' }} onClick={readMore}>Leer más</button>
      <button id='button_rosado_default' style={{ width: '80%', height: '7vh', margin: '5%' }} onClick={finish}>Terminar</button>
    </React.Fragment>
  )
}

type FinishReading2Props = {
  elapsedTime: number;
}
function FinishReading2({ elapsedTime }: FinishReading2Props) {
  const location = useLocation()
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [_, __, getCurrentBook, registerData, setWasReadingtheBook] = useReadingToday();
  const onSubmit = async (data: any) => {
    data.tiempo_leído = elapsedTime;
    data.fecha_lectura = new Date().toISOString();
    const currentBook = await getCurrentBook();
    const newReadingRecordDocumentId = await registerData(currentBook.id, data);
    const parentPathName = getParentPath(location.pathname);
    const route = `${parentPathName}/summary?recordId=${newReadingRecordDocumentId}&bookId=${currentBook.id}`;
    if (data.fue_terminado_el_libro) {
      await setWasReadingtheBook(currentBook.id);
    }
    navigate(route);
  }
  return (
    <React.Fragment>
      <p className={styleVistaVideo.descripcion}>
        Muy bien, leiste {Math.ceil(elapsedTime / 60)} minutos.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <div className='item-contraseña'>
          <label >¿En qué página quedaste?</label>
          <input
            type="text"
            {...register('página_final')}
          />
        </div>
        <div className='item-contraseña'>
          <label >¿Ya terminaste el libro?</label>
          <input
            type="checkbox"
            {...register('fue_terminado_el_libro')}
          />
        </div>
        <button id='button_rosado_default' type="submit" style={{ width: '80%', height: '7vh', margin: '5%' }}>Resumen</button>
      </form>
    </React.Fragment>
  )
}

function FinishReadingScreen() {
  const [pageIndex, setPageIndex] = useState(0)
  const location = useLocation();
  const [elapsedTime, setElapsedTime] = React.useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const { search } = location;
    const queryParams = new URLSearchParams(search);
    let time = parseInt(queryParams.get('elapsedTime') || '0', 10);
    setElapsedTime(time);
  }, [location]);
  const readMore = () => {
    const parentPathName = getParentPath(location.pathname);
    const route = `${parentPathName}/stopwatch?initialTime=${elapsedTime}`;
    navigate(route);
  }
  const finish = () => {
    setPageIndex(1)
  }
  if (elapsedTime === 0) {
    return (
      <Layout title='Terminar de Leer'>
        <Loading isOpen />
      </Layout>)
  }
  return (
    <div id='page'>
      <div id='toolbar_default'>
        <h1 className="title">Terminé de Leer</h1>
      </div>
      <div id='content_default' style={{ padding: '10.5% 5%', height: '100svh', gap: '5%' }}>
        {pageIndex === 0 && <FinishReading1 readMore={readMore} finish={finish} elapsedTime={elapsedTime} />}
        {pageIndex === 1 && <FinishReading2 elapsedTime={elapsedTime} />}
      </div>
    </div>
  )
}

export default FinishReadingScreen