import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import estiloEntrenamiento from '../../../../../../theme/entrenamiento/EntrenamientoPage.module.css';
import estiloSelector from '../../../../../../theme/programas/componentes/selectorNivel.module.css';

import { Entrenamiento } from '../../models/Entrenamiento';
import { changeCondicion, newActivity } from '../../../../../../redux/actividad/reducerActividad';
import { ChangeRedux_current_entrenamiento, ChangeRedux_current_registro_usuario, ChangeRedux_actividades_entrenamiento, ChangeRedux_current_actividades, ChangeRedux_current_indice_actividad, ChangeRedux_current_sesion } from '../../../../../../redux/entrenamiento/reducerEntrenamiento';
import { useAppSelector, useAppDispatch } from '../../../../../../redux/store';
import EntrenamientoBox from '../components/EntrenamientoBox';

const EntrenamientoPage = ({cargando}:{cargando:boolean}) => {

    const entrenamientos_app = useAppSelector((state) => state.entrenamiento.entrenamientos_app);
    const entrenamientos_user = useAppSelector((state) => state.entrenamiento.entrenamientos_user);
    const registros_user = useAppSelector((state) => state.entrenamiento.registros_usuario);

    const [loading, setLoading] = useState(cargando);
    const [openpopover, setOpenpopover] = useState(false);
    const [selectEntrenamiento, setSelectEntrenamiento] = useState(null);
    
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    //Constates 
    var nomNeurobics = 'neurobics';
    var nomMemoria = 'memoria';
    var nomSuperCerebro = 'entrenatusupercerebro';
    var nomSuperIngles = 'superIngles';
    var nomSuperLectura = 'superlectura';
    var nomSuperNutricion = 'superNutricion';
    var nomSuperCuboRubik = 'superCuboRubik';

    const Consulta = async () => {
        dispatch(changeCondicion({condicion:10}))
        dispatch(newActivity({id:null,name:"entrenamiento"}))
    }
    

    useEffect(() => {
        setLoading(cargando);
        if(cargando) Consulta();
    }, [cargando]);

    useEffect(() => {
      if(selectEntrenamiento === null){
        setOpenpopover(false)
      }else{
        setOpenpopover(true)
      }    
    }, [selectEntrenamiento])
    
    
    const HandleRedirectEntrenamiento = async(idEntrenamientoInput:string) => {
        if(!cargando){
            let entrenamientoUsuario = entrenamientos_app.find(elemento => elemento.id === idEntrenamientoInput);
            let asignado = entrenamientos_user.includes(idEntrenamientoInput);
            dispatch(newActivity({id:idEntrenamientoInput,name:"entrenamiento"}))
            if(!asignado){
                setLoading(false)
                if(idEntrenamientoInput === nomNeurobics){
                    return navigate(`/my/entrenamiento/${idEntrenamientoInput}/promocion/${idEntrenamientoInput}`)
                }else{
                    setSelectEntrenamiento(idEntrenamientoInput)
                }
            }else{
                let entrenamineto:Entrenamiento = entrenamientoUsuario
                dispatch(ChangeRedux_current_entrenamiento({entrenamiento:entrenamineto}))
                let registroActual = registros_user.find(elemento => elemento.idEntrenamiento === idEntrenamientoInput)
                dispatch(ChangeRedux_current_registro_usuario({registro:registroActual}));
                dispatch(ChangeRedux_actividades_entrenamiento({actividades_entrenamiento:null}))
                dispatch(ChangeRedux_current_actividades({current_actividades: null}))
                dispatch(ChangeRedux_current_indice_actividad(null))
                dispatch(ChangeRedux_current_sesion({sesion: null}))

                return navigate(`/my/entrenamiento/${idEntrenamientoInput}`)
            }

        }
    }

    const HandleRedirectRutaEstatica = (idEntrenamiento:string) => {
        switch (idEntrenamiento) {
            case (nomSuperIngles):
                navigate('consultaIngles')
                break
            default:
                console.error('Entrenamiento not found.')
                break
        }
    }

    const BuscarEntrenamientoSiExiste = (nomEntrenamiento:string): string => {
        let bandera = entrenamientos_user?.find((element) => element === nomEntrenamiento)
        return !bandera ? estiloEntrenamiento.entrenamientoDiv_block : ''
    }

    return (
        <div id='page'>
            <div id='toolbar_default'>
                <h1 className="ion-text-center title">Entrenamientos</h1>
            </div>
            <div id='content_default' style={{padding:'7.6% 3.4%'}}>
                <div className={estiloEntrenamiento.entrenamientoGrid}>
                    <EntrenamientoBox cargando={cargando} titulo='Neurobics' idEntrenamiento={nomNeurobics}
                    HandleRedirectEntrenamiento={() => HandleRedirectEntrenamiento(nomNeurobics)} 
                    style={{width:'100%'}}></EntrenamientoBox>

                    <EntrenamientoBox cargando={cargando} titulo='despierta <strong>tu mente dorada</strong>' idEntrenamiento={nomMemoria}
                    HandleRedirectEntrenamiento={() => HandleRedirectEntrenamiento(nomMemoria)} 
                    style={{width:'100%'}}></EntrenamientoBox>

                    <EntrenamientoBox cargando={cargando} titulo='<strong>entrena</strong> tu supercerebro' idEntrenamiento={nomSuperCerebro}
                    HandleRedirectEntrenamiento={() => HandleRedirectEntrenamiento(nomSuperCerebro)} 
                    style={{width:'100%'}}></EntrenamientoBox>

                    <EntrenamientoBox cargando={cargando} titulo='super <strong>inglés</strong>' idEntrenamiento={nomSuperIngles}
                    HandleRedirectEntrenamiento={() => {BuscarEntrenamientoSiExiste(nomSuperIngles) === '' ? HandleRedirectRutaEstatica(nomSuperIngles):setSelectEntrenamiento(nomSuperIngles)}} 
                    style={{width:'100%'}}></EntrenamientoBox>

                    <EntrenamientoBox cargando={cargando} titulo='super <strong>lectura</strong>' idEntrenamiento={nomSuperLectura}
                    HandleRedirectEntrenamiento={() => HandleRedirectEntrenamiento(nomSuperLectura)} 
                    style={{width:'100%'}}></EntrenamientoBox>

                    <EntrenamientoBox cargando={cargando} titulo='super <strong>nutrición</strong>' idEntrenamiento={nomSuperNutricion}
                    HandleRedirectEntrenamiento={() => {setSelectEntrenamiento(nomSuperNutricion)}} 
                    style={{width:'100%'}}></EntrenamientoBox>

                    <EntrenamientoBox cargando={cargando} titulo='super <strong>cubo rubik</strong>' idEntrenamiento={nomSuperCuboRubik}
                    HandleRedirectEntrenamiento={() => {setSelectEntrenamiento(nomSuperCuboRubik)}} 
                    style={{width:'100%'}}></EntrenamientoBox>
                </div>
            </div>   
            {openpopover && <div id="modal_cortina_default" onClick={() => setSelectEntrenamiento(null)}>
                <div className={estiloEntrenamiento.modal}>
                    <div onClick={() => setOpenpopover(false)}>
                        <label className={`title-mensaje 
                        ${selectEntrenamiento === nomSuperCuboRubik ? estiloEntrenamiento.cubo: 
                        selectEntrenamiento === nomMemoria ? estiloEntrenamiento.cerebro : 
                        selectEntrenamiento === nomSuperNutricion ? estiloSelector.palabras : '' }`}>PRÓXIMAMENTE</label>  
                        <img src={`/assets/images/cardEntrenamiento/${
                            selectEntrenamiento === nomSuperCuboRubik ? 'cubo.png': 
                            selectEntrenamiento === nomMemoria ? 'cerebro.png' : 
                            selectEntrenamiento === nomSuperNutricion ? 'nutricion.png' : 'ingles.png'
                        }`}></img>          
                        <p className='mensaje '>
                        <strong>Curso</strong> <br></br> {selectEntrenamiento}
                        </p>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default EntrenamientoPage
