import React, { PropsWithChildren } from 'react'
type LayoutProps = {
    title: string
}
function Layout({ children, title }: PropsWithChildren<LayoutProps>) {
    return (
        <div id='page'>
            <div id='toolbar_default'>
                <h1 className="title">{title}</h1>
            </div>
            <div id='content_default' style={{ padding: '10.5% 5%', height: '100svh', gap: '2%' }}>
                {children}
            </div>
        </div>
    )
}

export default Layout