import '../../theme/variables.css';

import { useRef, useState, useEffect } from 'react';
import { Route, Navigate, Routes, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';
import { DatoContext } from '../../modelos/auth';
import { loadAuth, initialNeuronas, login } from '../../redux/auth/reducerAuth';
import { useAppSelector } from '../../redux/store';
import { useControl } from '../../repositorio/controlador/useControl';
import useNeurona from '../../repositorio/controlador/useNeurona';
import ResponseePayco from '../../RespuestaEpayco';
import RespuestaPixel from '../../RespuestaPixel';
import LoadingPageComplete from '../components/LoadingPageComplete';
import NotFoundPage from '../NotFoundPage';
import CuentaRegresivaPresentacion from './anexos/CuentaRegresivaPresentacion';
import MemorizacionNumeros from './anexos/MemorizacionNumeros';
import MemorizacionPalabras from './anexos/MemorizacionPalabras';
import PruebaPage from './anexos/PruebaPage';
import LoginPage from './LoginPage';
import JuegoSimultaneo from './PvP/JuegoSimultaneo';
import AppTabs from './rutasPrivadasMy/AppTabs';
import DatosPage from './rutasPrivadasMy/home/perfil/DatosPage';
import RegisterPage from './rutasPrivadasMy/home/perfil/RegisterPage';



const App: React.FC = () => {

	/**
	 * Funciones para acceder el estado de autenticacion
	 * @type {Objecto}
	 * @const
	 */

	const dispatch = useDispatch();

	const loadingAuth = useAppSelector((state) => state.auth.loadingAuth);
	const loggedIn = useAppSelector((state) => state.auth.loggedIn);
	const userId = useAppSelector((state) => state.auth.userId);

	/**
	 * Funciones para cambiar estado de aplicacion
	 * @type {Objecto}
	 * @const
	 */
	const {
		borrarCuenta,
		logOutCuenta,
		initializarDatos,
		guardarDatos,
		recogerDatos,
		state,
	} = useControl();

	const { consultarNeuronas } = useNeurona();

	const navigate = useNavigate();

	const [cargando, setCargando] = useState(true);

	const originUrl = useRef(window.location.pathname);

	const Guardar = async (userId: string) => {
		dispatch(loadAuth());
		await recogerDatos(userId);
		const neuronas = await consultarNeuronas(userId)
		dispatch(initialNeuronas({ cantidad: neuronas.total }))
		dispatch(loadAuth());
	}

	useEffect(() => {
		const unsuscribe = onAuthStateChanged(auth, user => {
			console.log('user Login', user);
			if (user) {
				dispatch(login({ userId: user.uid, email: user.email }));
				Guardar(user.uid).then(() => {
					console.log("originUrl", originUrl.current);

					if (originUrl.current) {
						if (originUrl.current.includes("my/juegos")) {
							console.log('Incluye my/juegos')
							const params = originUrl.current.split("/");
							const subDomain = params[params.findIndex(v => 'juegos' === v.toLowerCase()) + 1]
							if (subDomain) navigate(`my/juegos/${subDomain}`);
							else navigate('/my/juegos');
						} else {
							console.log('Incluye nada ruta')
							navigate(originUrl.current);
						}
					} else if (window.location.pathname.includes('/my') || window.location.pathname.includes('/login') || window.location.pathname === "/") {
						navigate('/my/home');
					}
					setCargando(false);
				});

			} else {
				if (!window.location.pathname.includes('/torneo')) {
					navigate('/login');
				}
				setCargando(false);
			}
			unsuscribe();
		})

		if (!window.location.pathname.includes('/my')) {
			setCargando(false);
		}


	}, []);

	if (cargando) {
		return <LoadingPageComplete />;
	}



	return (
		<div id='App'>
			{/*
	      * Guardar functiones para acceder al estado de aplicacion para acceder despues
	      */}

			<DatoContext.Provider value={{
				borrarCuenta,
				logOutCuenta,
				initializarDatos,
				guardarDatos,
				recogerDatos,
				state,
			}}>
				<Routes>
					<Route path="/torneo/:idSala?" element={<div className={!userId ? 'divAllView' : ''} style={!userId ? {} : { height: '109%' }}><JuegoSimultaneo /></div>} />
					<Route path="/login" element={<LoginPage />} />
					<Route path="/datos" element={<DatosPage />} />
					<Route path="/register" element={<RegisterPage />} />
					<Route path="my/*" element={<AppTabs />} />
					<Route path="/" element={<Navigate to="/my/home" replace />} />

					{/* Anexos a la app */}

					<Route path="/prueba" element={<PruebaPage />} />
					<Route path="/presentacionMemorizacionPalabras" element={<MemorizacionPalabras />} />
					<Route path="/presentacionMemorizacionNumeros" element={<MemorizacionNumeros />} />
					<Route path="/cuentaRegresiva" element={<CuentaRegresivaPresentacion />} />
					<Route path="/responseFactura" element={<div className={'divAllView'} style={{ height: '109%' }}><ResponseePayco /></div>} />
					<Route path="/RealizadaCompra" element={<RespuestaPixel />} />
					<Route path='*' element={<Navigate to="/login" replace />} />
				</Routes>
			</DatoContext.Provider>
			<div id="toast"></div>
		</div>
	);
}

export default App;
