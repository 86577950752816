import React, { useEffect, useState } from 'react'
import { useReadingToday } from '../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../../shared/UI/Layout';
import Loading from '../../../../vista/components/Loading';

function ReadingTodayCheckInformation() {
    const [myBooks] = useReadingToday();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const getMyBooks = async () => {
            const books = await myBooks();
            const currentBook = books.find((book: any) => !book.fue_el_libro_leído);
            const route = currentBook ? `${location.pathname}/goToRead` : `${location.pathname}/initialConfig`;
            navigate(route)
        }
        getMyBooks();
    }, [myBooks, location.pathname, navigate])
    return (
        <Layout title='Lectura hoy'>
            <Loading isOpen />
        </Layout>
    )
}

export default ReadingTodayCheckInformation