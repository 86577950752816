import React, { useCallback, useEffect, useState } from 'react'
import styleVistaVideo from "../../../../theme/entrenamiento/componentes/VistaVideo.module.css";
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReadingToday } from '../../hooks';
import Layout from '../../../shared/UI/Layout';
import Loading from '../../../../vista/components/Loading';
import useRegistroEntrenamiento from '../../../../vista/rutasPublicas/rutasPrivadasMy/entrenamiento/repository/useRegistroEntrenamiento';
import { useAppSelector } from '../../../../redux/store';
import Cammera from './Cammera';
import { useAlmacenamiento } from '../../../../repositorio/baseDeDatos/useAlmacenamiento';
import { dataURLtoBlob, getTodayInString } from '../../../../vista/rutasPublicas/rutasPrivadasMy/entrenamiento/utils/UtilsEntrenamiento';
import { getDownloadURL } from 'firebase/storage';


function Summary() {
    const { register, handleSubmit } = useForm();
    const location = useLocation();
    const navigate = useNavigate();
    const [bookId, setBookId] = useState("");
    const [recordId, setRecordId] = useState("");
    const [_, __, getCurrentBook, registerData, setWasReadingtheBook, updateRecordData] = useReadingToday();
    const {
        actualizarRegistroEntrenamiento,
    } = useRegistroEntrenamiento();
    const { guardarArchivo } = useAlmacenamiento();

    const emailUsuario = useAppSelector((state) => state.auth.email);
    const userId = useAppSelector((state) => state.auth.userId);
    const idEntrenamiento = useAppSelector((state) => state.entrenamiento.current_entrenamiento.id);
    const indiceSesion = useAppSelector((state) => state.entrenamiento.current_sesion);
    const indiceActividad = useAppSelector((state) => state.entrenamiento.current_actividad);
    const lenghtActividades = useAppSelector((state) => state.entrenamiento.current_actividades?.length);
    const [isTakingPhoto, setIsTakingPhoto] = useState(false);
    const [isSendingInformation, setIsSendingInformation] = useState(false);
    const onSubmit = async (data: any) => {
        setIsSendingInformation(true);
        data.resumen = {
            tipo: "texto",
            contenido: data.resumen
        }
        await updateAndRedirect(data);
    }
    const updateAndRedirect = useCallback(async (data) => {
        await updateRecordData(bookId, recordId, data);
        actualizarRegistroEntrenamiento(emailUsuario, userId, idEntrenamiento, indiceSesion + 1, indiceActividad + 1);
        setIsSendingInformation(false);
        navigate(lenghtActividades === 1 ? `/my/entrenamiento/${idEntrenamiento}` : `/my/entrenamiento/${idEntrenamiento}/${indiceSesion}`);
    }, [actualizarRegistroEntrenamiento, bookId, emailUsuario, idEntrenamiento, indiceActividad, indiceSesion, lenghtActividades, navigate, recordId, updateRecordData, userId])

    const sendPhoto = useCallback(async (imgSrc: string) => {
        setIsSendingInformation(true);
        const fileName = `captura_resumen_${getTodayInString()}.jpg`;
        const blob = dataURLtoBlob(imgSrc); // Convertir a Blob
        // 🟢 Cambiar el nombre a JPG
        const file = new File([blob], fileName, { type: "image/jpeg" });
        const storageRef = await guardarArchivo(file, `resumenes/${userId}/${file.name}`);
        const data = {
            resumen: {
                tipo: "url",
                contenido: await getDownloadURL(storageRef)
            }
        }
        await updateAndRedirect(data);
    }, [guardarArchivo, updateAndRedirect, userId]);

    useEffect(() => {
        const { search } = location;
        const queryParams = new URLSearchParams(search);
        const bookId = queryParams.get('bookId') || '';
        const recordId = queryParams.get('recordId') || '';
        setBookId(bookId);
        setRecordId(recordId);
    }, [location]);
    if (!bookId || !recordId || isSendingInformation) {
        return (
            <Loading isOpen />
        )
    }
    if (isTakingPhoto) {
        return (<Cammera onFinish={sendPhoto} />)
    }
    return (
        <React.Fragment>
            <p className={styleVistaVideo.descripcion}>Cuéntame qué entendiste de la lectura que realizaste el día de hoy</p>
            <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <div className='item-contraseña'>
                    <label >Resumen</label>
                    <textarea
                        rows={10}
                        style={{ alignSelf: "stretch" }}
                        {...register('resumen')}
                    />
                </div>
                <input type='submit' id='button_rosado_default' style={{ width: '80%', height: '7vh', margin: '5%' }} value={"Teminé mi resumen"} />
            </form>
            <button id='button_rosado_default' style={{ width: '80%', height: '7vh', margin: '5%' }} onClick={() => setIsTakingPhoto(true)}>Tomar foto del resumen 📷</button>

        </React.Fragment>
    )
}
function SummaryScreen() {
    return (
        <div id='page'>
            <div id='toolbar_default'>
                <h1 className="title">Resumen</h1>
            </div>
            <div id='content_default' style={{ padding: '10.5% 5%', height: '100svh', gap: '5%' }}>
                <Summary />
            </div>
        </div>
    )
}

export default SummaryScreen