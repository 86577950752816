import { CollectionReference, DocumentData, DocumentSnapshot } from "firebase/firestore"
import { useAppSelector } from "../../../redux/store"
import { useBaseDeDatos } from "../../../generalUtils/repositoryUtils/useBaseDeDatos"
import * as databaseNames from "../../../datos/nombresBD"
import { useCallback, useEffect, useState } from "react";

export default function useReadingToday():[
    ()=>Promise<DocumentData[]>,
    (bookData:any)=>Promise<string>,
    ()=>Promise<DocumentData|undefined>,
    (bookId:string,recordData:any)=>Promise<string>,
    (bookId:string)=>Promise<void>,
    (bookId:string,recordId:string,recordData:any)=>Promise<void>,
    (bookId:string,bookData:any)=>Promise<void>
    ] {
    const [userId] = useAppSelector((state) => [state.auth.userId]);
    const {recogerCollection,agregarCollection,ActualizarDoc}=useBaseDeDatos();
    const {nomUsuarios,myBooksName}=databaseNames;
    
    const getBooks = useCallback(async () => {
        var myBooks= await recogerCollection(`${nomUsuarios}/${userId}/${myBooksName}`);
        return myBooks;
    }, [recogerCollection, nomUsuarios, userId, myBooksName]);

    const getCurrentBook = useCallback(async () => {
        const books = await getBooks();
        const currentBook = books.find((book: any) => !book.fue_el_libro_leído);
        return currentBook;
    }, [getBooks]);

    const addBook = useCallback(async (bookData:any) => {
        const route= `${nomUsuarios}/${userId}/${myBooksName}`;
        const newDocumentId=await agregarCollection(route,bookData);
        await ActualizarDoc(`${route}/${newDocumentId}`,{id:newDocumentId});
        return newDocumentId;
    }, [nomUsuarios, userId, myBooksName, agregarCollection, ActualizarDoc]);
    
    const addReadingRecord = useCallback(async (bookId:string,recordData:any) => {
        const route= `${nomUsuarios}/${userId}/${myBooksName}/${bookId}/registro_lectura`;
        const newDocumentId=await agregarCollection(route,recordData);
        await ActualizarDoc(`${nomUsuarios}/${userId}/${myBooksName}/${bookId}/registro_lectura/${newDocumentId}`,{id:newDocumentId});
        return newDocumentId;
    }, [ActualizarDoc, agregarCollection, myBooksName, nomUsuarios, userId]);

    const setWasReadingtheBook = useCallback(async (bookId:string) => {
        const books = await getBooks();
        const book = books.find((book: any) => book.id === bookId);
        if(book){
            await ActualizarDoc(`${nomUsuarios}/${userId}/${myBooksName}/${bookId}`,{fue_el_libro_leído:true});
        }
    }, [ActualizarDoc, getBooks, myBooksName, nomUsuarios, userId]);
    
    const updateReadingRecord = useCallback(async (bookId:string,recordId:string,recordData:any) => {
        await ActualizarDoc(`${nomUsuarios}/${userId}/${myBooksName}/${bookId}/registro_lectura/${recordId}`,recordData);
    }, [ActualizarDoc, myBooksName, nomUsuarios, userId]);
    const updateBook= useCallback(async (bookId:string,bookData:any) => {
        await ActualizarDoc(`${nomUsuarios}/${userId}/${myBooksName}/${bookId}`,bookData);
    }, [ActualizarDoc, myBooksName, nomUsuarios, userId]);
    return [
        getBooks,
        addBook,
        getCurrentBook,
        addReadingRecord,
        setWasReadingtheBook,
        updateReadingRecord,
        updateBook
    ]
}
