import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useReadingExcercise } from '../../hooks';
import Layout from '../../../shared/UI/Layout';
import Loading from '../../../../vista/components/Loading';
import { calculatePPM, countWords, getParentPath } from '../../../../vista/rutasPublicas/rutasPrivadasMy/entrenamiento/utils/UtilsEntrenamiento';
type StopWatchProps = {
    time: number;
    setTime: React.Dispatch<React.SetStateAction<number>>
}
function Stopwatch({ time, setTime }: StopWatchProps) {
    const [isRunning, setIsRunning] = React.useState(true);
    const timerRef = React.useRef<NodeJS.Timeout | null>(null);
    React.useEffect(() => {
        if (isRunning) {
            timerRef.current = setInterval(() => {
                setTime(prevTime => prevTime + 1);
            }, 1000);
        } else if (!isRunning && timerRef.current) {
            clearInterval(timerRef.current);
        }
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [isRunning]);

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    };

    return (
        <div className="">
            <span style={{ textAlign: "right" }}>Cronómetro: {formatTime(time)}</span>
        </div>
    );
}
type ReadingProps = {
    reading: any;
    time: number;
    setTime: React.Dispatch<React.SetStateAction<number>>;
    finishReading: () => void;
}
function Reading({ reading, time, setTime, finishReading }: ReadingProps) {
    const [fontSizeBase, setFontSizeBase] = useState(0);
    return (
        <React.Fragment>
            <div style={{ alignSelf: "stretch", textAlign: "right" }} >
                <Stopwatch time={time} setTime={setTime} />
            </div>
            <div style={{ flex: 1, overflowY: 'scroll' }} >
                <h2 style={{ textAlign: "center", marginBottom: "10px", fontFamily: "Montserrat", fontSize: `${fontSizeBase + 18}px`, fontWeight: "bolder" }}>{reading.titulo}</h2>
                <p style={{ fontSize: `${fontSizeBase + 16}px` }}>
                    {reading.texto}
                </p>
            </div>
            <div style={{ display: "flex", gap: 12 }}>
                <button onClick={() => setFontSizeBase(fontSizeBase + 2)} disabled={fontSizeBase > 10}>+</button>

                <button onClick={() => setFontSizeBase(fontSizeBase - 2)} disabled={fontSizeBase < 0}>-</button>
            </div>
            <button id='button_rosado_default' style={{ width: '80%', height: '7vh', margin: '2%' }} onClick={finishReading}>Terminé de leer</button>
        </React.Fragment>
    )
}

function ReadingScreen() {
    const [reading, setReading] = useState(null)
    const [time, setTime] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const [_, getReadingById] = useReadingExcercise();
    const finishReading = useCallback(async () => {
        if (!reading) return
        const wordCount = countWords(reading.texto)
        const routeCallback = encodeURIComponent(`${getParentPath(location.pathname)}/Result?readingId=${reading.id}&ppm=${calculatePPM(wordCount, time)}&comprehension=[comprehension]`)
        navigate(`${getParentPath(getParentPath(location.pathname))}/cuestionario/${reading.idCuestionario}?routeCallback=${routeCallback}`)
    }, [location.pathname, navigate, reading, time])
    useEffect(() => {
        const getReading = async () => {
            const { search } = location;
            const queryParams = new URLSearchParams(search);
            const readingId = queryParams.get("readingId") || '0';
            const reading = await getReadingById(readingId);

            setReading(reading)
        }
        getReading();

    }, [getReadingById, location])
    return (
        <Layout title="Lectura">
            {!reading && <Loading isOpen />}
            {!!reading && <Reading reading={reading} time={time} setTime={setTime} finishReading={finishReading} />}
        </Layout>
    )
}

export default ReadingScreen