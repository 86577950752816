import '../../../../../../theme/actividades.css';
import styleVistaVideo from "../../../../../../theme/entrenamiento/componentes/VistaVideo.module.css";
import stylePrograma from "../../../../../../theme/admin/programa.module.css";
import estiloJuegos from "../../../../../../theme/Juegos.module.css";

import React, { useState, useEffect, useRef } from 'react';

import VideoConContador from '../components/VideoConContador';
import useRegistroEntrenamiento from '../../repository/useRegistroEntrenamiento';
import { useDato } from '../../../../../../modelos/auth';
import { Actividad } from '../../../../../../modelos/entrenamiento/Actividad';
import { RegistroActividad, registroActividadVacio } from '../../../../../../modelos/entrenamiento/RegitroActividad';
import { sumaNeuronas } from '../../../../../../redux/auth/reducerAuth';
import { useAppSelector, useAppDispatch } from '../../../../../../redux/store';
import useNeurona from '../../../../../../repositorio/controlador/useNeurona';
import PopoverNeuronas from '../../../../../components/PopoverNeuronas';
import { isValidHttpUrl } from '../../utils/UtilsEntrenamiento';

import Vimeo from "@u-wave/react-vimeo";


interface Props {
    handleBack: () => Promise<void>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    videoUrl: string | string[];
    actividad: Actividad;
    firstTime: (0 | 1 | 2);
    GuardarRegistro: () => Promise<void>;
}

const VistaDeVideo: React.FC<Props> = (props) => {
    const sesionActual = useAppSelector((state) => state.entrenamiento.current_entrenamiento.sesiones[state.entrenamiento.current_sesion]);
    const userId = useAppSelector((state) => state.auth.userId);
    const currentEntrenamientoRedux = useAppSelector((state) => state.entrenamiento.current_entrenamiento);
    const { crearRegistroActividad, actualizarRating, agregarRating, consultarLike } = useRegistroEntrenamiento();
    const { actualizarNeuronas } = useNeurona();
    const { state } = useDato();
    const [registroActividad, setRegistroActividad] = useState<RegistroActividad>(registroActividadVacio);
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const [showNeuronas, setShowNeuronas] = useState<boolean>(false);
    const [neuronasGanadas, setNeuronasGanadas] = useState<number>(0);
    const [mensaje, setMensaje] = useState<string>('¡Completa el video la próxima vez para maximizar tus ganancias!');
    const dispatch = useAppDispatch();
    const [isAnimatingExit, setIsAnimatingExit] = useState(false);
    const [isAnimatingEntrada, setIsAnimatingEntrada] = useState(false);

    useEffect(() => {
        componentDidMount()
        return () => {
            componentWillUnmount()
        }
    }, [])

    const componentWillUnmount = async () => {
        setShowNeuronas(false)
        const largo = document.getElementById('el-body').clientHeight;
        const ancho = document.getElementById('el-body').clientWidth;
        if ((largo * 3 / 4) >= ancho) {
            document.getElementById('el-body').style.background = '#ffffff';
        } else {
            document.getElementById('el-body').style.background = 'linear-gradient(180deg, #45245F 0%, #D74285 100%)';
        }
    }

    const componentDidMount = async () => {
        let registroActividad = await consultarLike(userId, currentEntrenamientoRedux.id, `${sesionActual?.id}${props.actividad?.id}`)
        setShowNeuronas(false)
        if (registroActividad) setRegistroActividad(registroActividad);
    }

    const componenteVideoRef = useRef<VideoConContador | null>(null);

    const llamarFuncionInfo = (): number => {
        if (componenteVideoRef.current) {
            const respuestas = componenteVideoRef.current.capturarInfoVideo()
            return respuestas
        } else {
            return 0
        }
    };

    const GenerarRegistrodelLikeInicializar = async (NEstrellasInput?: number) => {
        if (!registroActividad.id) {
            let copy = { ...registroActividad }
            copy.id = `${sesionActual?.id}${props.actividad?.id}`
            if (copy.like !== NEstrellasInput && typeof (NEstrellasInput) === typeof (0)) {
                copy.like = NEstrellasInput
            }
            setRegistroActividad(copy)
            await agregarRating(currentEntrenamientoRedux.id, sesionActual?.id, props.actividad?.id, copy)
            crearRegistroActividad(userId, currentEntrenamientoRedux.id, copy)
        } else if (registroActividad.like !== NEstrellasInput) {
            let antiguoLike = registroActividad.like
            let copy = { ...registroActividad }
            copy.like = NEstrellasInput
            setRegistroActividad(copy)
            await actualizarRating(currentEntrenamientoRedux.id, sesionActual?.id, props.actividad?.id, copy, antiguoLike)
            crearRegistroActividad(userId, currentEntrenamientoRedux.id, copy)
        }
    }

    const GuardarNeuronas = () => {
        dispatch(sumaNeuronas({ cantidad: neuronasGanadas }))
        actualizarNeuronas(userId, neuronasGanadas, state._usuarioDato.nombres)
        return (
            <></>
        )
    }

    useEffect(() => {
        if (showNeuronas == true) {
            GuardarNeuronas()
        }
    }, [showNeuronas])

    const salirOpopover = () => {
        let numero = llamarFuncionInfo()
        if (numero < 90) {
            props.setLoading(false)
            setOpenPopover((prev) => !prev)
            setMensaje('¡Completa el video la próxima vez para maximizar tus ganancias!')
        } else {
            props.setLoading(false)
            setMensaje('¡Felicidades! Has ganado 100 neuronas por tu compromiso con el entrenamiento. ¡Sigue así!')
            setOpenPopover((prev) => !prev)
            MostrarNeuronas()
        }
    }

    const MostrarNeuronas = (): void => {
        setOpenPopover((prev) => !prev)
        if (props.firstTime === (0 | 2)) {
            setShowNeuronas(true)
        } else {
            FunctionSalir()
        }
    }

    const FunctionSalir = async () => {
        console.log('Funcionb salir')
        props.setLoading(true)
        props.GuardarRegistro();
        setShowNeuronas(false);
        props.setLoading(false)
        return props.handleBack();
    }

    const salir = () => {
        props.setLoading(true)
        FunctionSalir()
        /*
        if (props.firstTime !== 1) {
            salirOpopover()
        } else {
            FunctionSalir()
        }*/
    }
    console.log('props.videoUrl', props.videoUrl)
    console.log('isValidHttpUrl(props.videoUrl)', isValidHttpUrl(props.videoUrl))
    return (
        <>
            <div className={`${styleVistaVideo.toolbar_Vista_video}`}>
                <div style={{ display: 'inherit', width: '100%', padding: '4em 2.5em' }}>
                    <button className={`${stylePrograma.backButton} ${styleVistaVideo.backButton}`} style={{ height: '100%' }} onClick={salir} />
                    <h1 className="sesionTitulo" style={{ width: '60%' }} >{props.actividad?.titulo ?? `Sesión {indexSesionActual+1}`}</h1>
                </div>
                <div className={estiloJuegos.contenedorVideo} style={{ height: 'fit-content', background: '#D9D9D9', borderRadius: '23px' }}>
                    {
                        props.videoUrl && !props.videoUrl.includes("vimeo.com") &&
                        <VideoConContador
                            autoplay={false}
                            style_contain={{ height: 'fit-content', position: 'relative', display: 'flex', justifyContent: 'center' }}
                            style_video={{ height: '100%', maxWidth: '100%' }}
                            setLoading={props.setLoading}
                            videoUrl={props.videoUrl}
                            setNeuronasGanadas={setNeuronasGanadas}
                            ref={componenteVideoRef}
                            adelantar={true} />
                    }
                    {
                        props.videoUrl && props.videoUrl.includes("vimeo.com") &&
                        <div
                          style={{
                            width: "100%",         // Ocupa todo el ancho del contenedor padre
                            maxWidth: "800px",      // Ancho máximo, puedes ajustarlo a lo que necesites
                            margin: "0 auto",       // Centra el contenedor
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Vimeo
                            video={props.videoUrl as string}
                            autoplay={false}
                            responsive
                            style={{ width: "100%", height: "100%" }}  // Se adapta al tamaño del contenedor
                            onReady={(player: any) => {
                              if (player && player.element) {
                                player.element.setAttribute("allow", "autoplay; fullscreen; picture-in-picture");
                                player.element.setAttribute("allowfullscreen", "");
                              }
                            }}
                            onPlay={() => console.log("play video with vimeo")}
                          />
                        </div>
                    }
                </div>
            </div>
            <div className={stylePrograma.content} style={{ position: 'relative', borderRadius: '0', padding: '0', overflow: 'hidden' }}>
                <p className={styleVistaVideo.descripcion} style={{ minHeight: '60%' }}>{props.actividad?.descripcion}</p>
                <button className={styleVistaVideo.ButonFinalizar} onClick={salir}>LISTO</button>
                <img className='imagenDificultad' src={`/assets/componentes/ActividadVideo/fondo_neuronas_gris.svg`}></img>
            </div>

            {/*
            {openPopover === true && <div onClick={() => { setOpenPopover((prev) => !prev) }} className={styleVistaVideo.popover}>
                <div className={styleVistaVideo.content} onClick={(e) => e.stopPropagation()}>
                    <div className={styleVistaVideo.top}>
                        <p>¡Estás a punto de completar!</p>
                        <p>Has visto el {neuronasGanadas} % del video</p>
                        {neuronasGanadas !== 100 && <p>¿Estás seguro de que quieres perder el contenido restante y las neuronas que puedes ganar?</p>}
                    </div>
                    <div className={styleVistaVideo.bot}>
                        {neuronasGanadas !== 100 && <button className={styleVistaVideo.botonRealizarActividadOkRosado} onClick={() => { MostrarNeuronas() }}>Sí</button>}
                        <button className={styleVistaVideo.botonRealizarActividadOk} onClick={() => setOpenPopover(false)}>No</button>
                    </div>

                </div>
            </div>}
            {showNeuronas &&
                <PopoverNeuronas nuevasNeuronas={neuronasGanadas} mensajeInfo={mensaje} nivel={neuronasGanadas} funcionVolver={() => FunctionSalir()} esEntrenamiento={true} />
            }
            */}
        </>
    )
}

export default VistaDeVideo
