import React from 'react'

function QuestionsScreen() {
  return (
    <div id='page'>
    <div id='toolbar_default'>
        <h1 className="title">Preguntas</h1>
    </div>
    <div id='content_default' style={{padding:'10.5% 5%',height:'100svh',gap:'2%'}}>
    <button id='button_rosado_default' style={{width:'80%',height:'7vh',margin:'5%'}} onClick={() => {}}>Finalizar</button>
    
    </div>
    </div>  
  )
}

export default QuestionsScreen