import { useState, useEffect, useLayoutEffect } from "react";
import { Route, Routes } from "react-router-dom";

import useRegistroEntrenamiento from "./repository/useRegistroEntrenamiento";
import ActividadesPage from "./UI/pages/ActividadesPage";
import EntrenamientoPage from "./UI/pages/EntrenamientoPage";
import FirstTimeEntrenamiento from "./UI/pages/FirstTimeEntrenamiento";
import LayoutEntrenamiento from "./utils/LayoutEntrenamiento";
import RealizarActividadPage from "./UI/pages/RealizarActividadPage";
import SesionesPage from "./UI/pages/SesionesPage";
import ConsultaIngles from "../inteligenciaArtificial/ConsultaIngles";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useEntrenamiento } from "../../../../repositorio/controlador/useEntrenamiento";
import { ChangeRedux_entrenamientos_user, ChangeRedux_entrenamientos_app, ChangeRedux_registros_usuario, ChangeRedux_cargando } from "../../../../redux/entrenamiento/reducerEntrenamiento";
import ResponseePayco from "../../../../RespuestaEpayco";
import JuegoCodificacionPage from "../../programas/pages/codificacion/JuegoCodificacionPage";
import JuegoCodigodePalabrasPage from "../../programas/pages/codigoPalabras/JuegoCodigodePalabrasPage";
import JuegoCuestionario from "../../programas/pages/JuegoCuestionario";
import JuegoValoresAsociados from "../../programas/pages/JuegoValoresAsociados";
import VelocidadLectura from "../../programas/pages/VelocidadLectura";
import LandingCurso from "./UI/pages/LandingCurso";
import GeneralPage from "./UI/pages/GeneralPage";
import ReadingTodayScreen from "../../../../modules/reading-today/UI/screens/ReadingToday.screen";
import ReadingExcerciseScreen from "../../../../modules/reading-excercise/UI/screens/ReadingExcercise.screen";

export const TabsEntreno = () => {
    const { coleccionEntrenamientos, consultarEntrenamientosApp } = useEntrenamiento();
    const { consultarRegistros } = useRegistroEntrenamiento();
    const dispatch = useAppDispatch();

    const userId = useAppSelector((state) => state.auth.userId);
    const asignados = useAppSelector((state) => state.entrenamiento.entrenamientos_user);
    const entrenamientos = useAppSelector(state => state.entrenamiento.entrenamientos_app);
    const registros = useAppSelector(state => state.entrenamiento.registros_usuario)

    const [loading, setLoading] = useState(true);

    const CargarSiEstaAldia = async (entrenamientosIds: string[]) => {
        let ids: string[] = [];
        (await coleccionEntrenamientos(userId)).forEach(doc => ids.push(doc.id));
        if (!(JSON.stringify(ids) === JSON.stringify(entrenamientosIds))) {
            dispatch(ChangeRedux_entrenamientos_user({ entrenamientos: ids }));
        }
        return asignados ?? ids;
    }

    const cargarAsignados = async () => {
        let ids: string[] = []
        if (!asignados) {
            (await coleccionEntrenamientos(userId)).forEach(doc => ids.push(doc.id));
            dispatch(ChangeRedux_entrenamientos_user({ entrenamientos: ids }));
        }
        return asignados ?? ids;
    }
    const cargaEntrenamientos = async () => {
        if (!entrenamientos) {
            const datos = await consultarEntrenamientosApp();
            dispatch(ChangeRedux_entrenamientos_app({ entrenamientos: datos }));
        }
    }
    const cargarRegistros = async (idsAsignados: string[]) => {
        if (!registros) {
            const datos = await consultarRegistros(userId, idsAsignados);
            dispatch(ChangeRedux_registros_usuario({ registros: datos }));
        }
    }
    const consulta = async () => {
        setLoading(true);
        const promesaIds = await cargarAsignados();
        CargarSiEstaAldia(promesaIds)
        const promesaEntrenamientos = cargaEntrenamientos();
        const ids = await promesaIds;
        await Promise.all([cargarRegistros(ids), promesaEntrenamientos]);
        setLoading(false);
    }

    useEffect(() => {
        dispatch(ChangeRedux_cargando(loading))
    }, [loading]);

    useLayoutEffect(() => {
        consulta();
    }, []);

    return (
        <Routes>

            <Route path="/" element={<EntrenamientoPage cargando={loading} />} />
            {/* juegos en los entrenamientos */}
            <Route path="codificacion/:nivel?" element={<JuegoCodificacionPage />} />
            <Route path="JuegoCorrespondencia/:limit" element={<JuegoValoresAsociados />} />
            <Route path="CodigoPalabras/:limit" element={<JuegoCodigodePalabrasPage />} />
            <Route path="LecturaVelocidad" element={<VelocidadLectura />} />
            <Route path="cuestionario/:id" element={<JuegoCuestionario />} />
            <Route path="LecturaHoy/*" element={<ReadingTodayScreen />} />
            <Route path="EjercicioLectura/*" element={<ReadingExcerciseScreen />} />

            {/* path de entrenamiento*/}
            <Route path=":idEntrenamiento" element={<LayoutEntrenamiento />}>
                <Route path="general" element={<GeneralPage />} />
                <Route path="promocion/:titleEntrenamiento" element={<LandingCurso />} />
                <Route path="firstTime/:titleEntrenamiento" element={<FirstTimeEntrenamiento />} />
                <Route path=":id/:index" element={<RealizarActividadPage />} />
                <Route path=":id" element={<ActividadesPage />} />
                <Route path="" element={<SesionesPage />} />
            </Route>
            <Route path="consultaIngles" element={<ConsultaIngles />} />
            <Route path="response" element={<ResponseePayco />} />


        </Routes>
    );
}