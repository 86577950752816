import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Layout from '../../../shared/UI/Layout'
import { useForm } from 'react-hook-form'
import { useReadingToday } from '../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { getParentPath } from '../../../../vista/rutasPublicas/rutasPrivadasMy/entrenamiento/utils/UtilsEntrenamiento';
import Loading from '../../../../vista/components/Loading';
import InitialConfigForm from '../components/InitialConfigForm';

function InitialConfigScreen() {
	const [currentBook, setCurrentBook] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [myBooks] = useReadingToday();
	const navigate = useNavigate();
	const [getBooks,
		addBook,
		getCurrentBook,
		addReadingRecord,
		setWasReadingtheBook,
		updateReadingRecord,
		updateBook] = useReadingToday();
	const location = useLocation();
	const onSubmit = useCallback(async (data: any) => {
		if (currentBook) {
			console.log("actualice")
			await updateBook(currentBook.id, data)
		} else {
			const newDocumentId = await addBook(data);
		}
		const parentPathName = getParentPath(location.pathname);
		const route = `${parentPathName}/goToRead`;
		navigate(route);
	}, [addBook, currentBook, location.pathname, navigate, updateBook])
	useEffect(() => {
		const getMyBooks = async () => {
			const books = await myBooks();
			const currentBook = books.find((book: any) => !book.fue_el_libro_leído);
			setCurrentBook(currentBook);
			setIsLoading(false);
		}
		getMyBooks();
	}, [myBooks]);
	if (isLoading) {
		return (
			<Layout title='Configuración Inicial'>
				<Loading isOpen />
			</Layout>
		)
	}
	return (
		<Layout title="Configuración Inicial">
			<InitialConfigForm onSubmit={onSubmit} defaultValues={currentBook} />
		</Layout>
	)
}

export default InitialConfigScreen