import { useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { useStore } from "react-redux";
import { Actividad } from "../../../../../modelos/entrenamiento/Actividad";
import { RegistroEntrenamiento } from "../../../../../modelos/entrenamiento/RegistroEntrenamiento";
import { newActivity } from "../../../../../redux/actividad/reducerActividad";
import {
  ChangeRedux_current_entrenamiento,
  ChangeRedux_current_registro_usuario,
  ChangeRedux_current_actividades,
  ChangeRedux_current_sesion,
  ChangeRedux_actividades_entrenamiento,
  agregarSesionVista,
} from "../../../../../redux/entrenamiento/reducerEntrenamiento";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { useEntrenamiento } from "../../../../../repositorio/controlador/useEntrenamiento";
import { Entrenamiento } from "../models/Entrenamiento";

export const useProxyEntrenamiento = (
  idEntrenamiento?: string,
  indexSesion?: string,
  indexActivity?: string
) => {
  const { consultarActividadesEntrenamiento, consultarActividades } =
    useEntrenamiento();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let CargarTodo = false;
  const entrenamientos_app = useAppSelector(
    (state) => state.entrenamiento.entrenamientos_app
  );
  const registros_user = useAppSelector(
    (state) => state.entrenamiento.registros_usuario
  );
  const redux_current_registro = useAppSelector(
    (state) => state.entrenamiento.current_registro_usuario
  );
  const redux_current_entrenamiento = useAppSelector(
    (state) => state.entrenamiento.current_entrenamiento
  );
  const redux_actividades_entrenamiento = useAppSelector(
    (state) => state.entrenamiento.actividades_entrenamiento
  );
  const sesionesVistas = useAppSelector(
    (state) => state.entrenamiento.sesionesVistas
  ); // Obtener sesiones vistas desde Redux

  let caso = (): string => {
    let url = window.location.pathname;
    if (
      url ===
      `/my/entrenamiento/${idEntrenamiento}/${indexSesion}/${indexActivity}`
    ) {
      return "carga-actividad";
    }
    if (
      url === `/my/entrenamiento/${idEntrenamiento}/${indexSesion}` ||
      indexSesion
    ) {
      return "carga-actividad";
    }
    if (url === `/my/entrenamiento/${idEntrenamiento}`) {
      return "carga-registro";
    }
    return "carga-registro";
  };

  const CargaEntrenamientoRegistroActual = () => {
    let returnEntrenamiento: Entrenamiento | null = null;
    let returnRegistro: RegistroEntrenamiento | null = null;
    if (redux_current_entrenamiento?.id !== idEntrenamiento) {
      CargarTodo = true;
      let entrenamiento = entrenamientos_app?.find(
        (entrenamiento) => entrenamiento.id === idEntrenamiento
      );
      if (!entrenamiento) {
        console.error(
          "No se encontro el index del registro al que pertenece",
          idEntrenamiento,
          entrenamientos_app
        );
        dispatch(ChangeRedux_current_entrenamiento({ entrenamiento: null }));
      } else {
        dispatch(ChangeRedux_current_entrenamiento({ entrenamiento }));
        returnEntrenamiento = entrenamiento;
      }
    } else {
      returnEntrenamiento = redux_current_entrenamiento;
    }
    if (redux_current_registro?.idEntrenamiento !== idEntrenamiento) {
      CargarTodo = true;
      let registro = registros_user?.find(
        (registro) => registro.idEntrenamiento === idEntrenamiento
      );
      if (registro) {
        dispatch(ChangeRedux_current_registro_usuario({ registro }));
        returnRegistro = registro;
      } else {
        dispatch(ChangeRedux_current_registro_usuario({ registro: null }));
        console.error(
          "No se encontro el index del registro al que pertenece",
          idEntrenamiento,
          registros_user
        );
      }
    } else {
      returnRegistro = redux_current_registro;
    }
    return { returnEntrenamiento, returnRegistro };
  };

  const CargarActividades = async (
    actualEntrenamiento: Entrenamiento,
    actualRegistro: RegistroEntrenamiento
  ) => {
    if (
      EncuentraMenorARegistro(Number(indexSesion) + 1, 1, actualRegistro) !==
      EstadosSesion.INCOMPLETO
    ) {
      let actividades: Actividad[] | null = redux_actividades_entrenamiento
        ? redux_actividades_entrenamiento[indexSesion]
        : null;
      if (!actividades || CargarTodo) {
        actividades = await consultarActividades(
          actualEntrenamiento.id,
          actualEntrenamiento.sesiones[indexSesion].id
        );
        console.log("🚀 ~ CargarActividades ~ actividades:", actividades);
        if (
          actualRegistro.ordenSesion === Number(indexSesion) + 1 &&
          actualRegistro.ordenActividad === actividades.length
        ) {
          let copy = { ...actualRegistro };
          copy.ordenSesion += 1;
          copy.ordenActividad = 0;
          copy.completadoSesion = false;
          console.log("🚀 ~ CargarActividades ~ copy:", copy);
          dispatch(ChangeRedux_current_registro_usuario({ registro: copy }));
        }
      }
      dispatch(
        ChangeRedux_current_actividades({ current_actividades: actividades })
      );
      dispatch(ChangeRedux_current_sesion({ sesion: Number(indexSesion) }));
    } else {
      console.error(
        "No cumple las condiciones para ir a la sesion",
        indexSesion + 1,
        " actividad ",
        indexActivity
      );
      navigate(`/my/entrenamiento/${idEntrenamiento}`);
    }
  };

  const CargaTodasLasActividadesDelEntrenamiento = async (
    actualEntrenamiento: Entrenamiento
  ) => {
    if (CargarTodo || !redux_actividades_entrenamiento) {
      let consultarTodasActividades = await consultarActividadesEntrenamiento(
        actualEntrenamiento
      );
      dispatch(
        ChangeRedux_actividades_entrenamiento({
          actividades_entrenamiento: consultarTodasActividades,
        })
      );
    }
  };

  return async (cargarSesion?: boolean) => {
    dispatch(newActivity({ id: "entrenamiento", name: "entrenamiento" }));
    const { returnEntrenamiento, returnRegistro } =
      CargaEntrenamientoRegistroActual();
    if (caso() === "carga-actividad" || (cargarSesion && returnRegistro)) {
      if (cargarSesion)
        indexSesion = (returnRegistro.ordenSesion - 1).toString();
      console.log("CargarActividades");
      CargarActividades(returnEntrenamiento, returnRegistro);
      CargaTodasLasActividadesDelEntrenamiento(returnEntrenamiento);

      // Registrar la sesión vista si no ha sido vista anteriormente
      if (!sesionesVistas.includes(Number(indexSesion))) {
        dispatch(agregarSesionVista(Number(indexSesion)));
      }
    } else {
      CargaTodasLasActividadesDelEntrenamiento(returnEntrenamiento);
    }
  };
};

export enum EstadosSesion {
  INCOMPLETO,
  COMPLETADO,
  ACTUAL,
}
//Devuelve True si el orden y actividad que se pasan se puede acceder (es decir o ya se completo o se encuentra en ese punto)
//devuelve 2 si es el actual y 1 si esta completado y 0 si no se ha realizado
export const CalcularEstadoDeSesion = (
  ordenSesion: number,
  currentRegistroUsuario: RegistroEntrenamiento
) => {
  if (currentRegistroUsuario?.completadoEntrenamiento) {
    return EstadosSesion.COMPLETADO;
  }
  if (
    ordenSesion === currentRegistroUsuario?.ordenSesion + 1 &&
    currentRegistroUsuario?.completadoSesion
  ) {
    return EstadosSesion.ACTUAL;
  }
  if (ordenSesion < currentRegistroUsuario?.ordenSesion) {
    return EstadosSesion.COMPLETADO;
  }
  if (ordenSesion === currentRegistroUsuario?.ordenSesion) {
    if (currentRegistroUsuario.completadoSesion) {
      return EstadosSesion.COMPLETADO;
    } else {
      return EstadosSesion.ACTUAL;
    }
  }
  return EstadosSesion.INCOMPLETO;
};

export const EncuentraMenorARegistro = (
  ordenSesion: number,
  ordenActividad: number,
  currentRegistroUsuario: RegistroEntrenamiento
) => {
  // Si el usuario ha completado el entrenamiento, todas las actividades se marcan como completadas
  if (currentRegistroUsuario?.completadoEntrenamiento) {
    return EstadosSesion.COMPLETADO;
  }

  // Calcula el estado de la sesión actual
  let Sesion = CalcularEstadoDeSesion(ordenSesion, currentRegistroUsuario);

  if (Sesion === EstadosSesion.COMPLETADO) {
    return EstadosSesion.COMPLETADO;
  } else if (Sesion === EstadosSesion.ACTUAL) {
    // Si es la primera actividad de la primera sesión
    if (currentRegistroUsuario?.ordenActividad === 0 && ordenActividad === 1) {
      return EstadosSesion.ACTUAL;
    }
    // Si la actividad actual es menor o igual a la última completada
    if (ordenActividad <= currentRegistroUsuario?.ordenActividad) {
      return EstadosSesion.COMPLETADO;
      // Si la actividad es la siguiente a la última completada
    } else if (ordenActividad - 1 === currentRegistroUsuario?.ordenActividad) {
      return EstadosSesion.ACTUAL;
    }
  }
  // Si no cumple con ninguna de las condiciones anteriores, se considera incompleta
  return EstadosSesion.INCOMPLETO;
};

export const useGenerarSiguienteRegistro: () => Promise<RegistroEntrenamiento> =
  () => {
    const store = useStore();

    // Promesa que espera la carga de actividades y calcula el siguiente registro
    const esperarActividadesCargadas = new Promise<RegistroEntrenamiento>(
      (resolve) => {
        const unsubscribe = store.subscribe(() => {
          const state: any = store.getState();
          const actividades: Actividad[][] =
            state.entrenamiento?.actividades_entrenamiento;
          const registroActual: RegistroEntrenamiento =
            state.entrenamiento?.current_registro_usuario;

          // Si las actividades están cargadas, se calcula el nuevo registro y se desuscribe
          if (actividades?.length > 0) {
            const nuevoRegistro = calcularNuevoRegistro(
              actividades,
              registroActual
            );
            resolve(nuevoRegistro);
            unsubscribe(); // Desuscribirse para evitar el bucle infinito
          }
        });
      }
    );

    // Función para calcular el nuevo registro
    const calcularNuevoRegistro = (
      actividades: Actividad[][],
      registroActual: RegistroEntrenamiento
    ) => {
      if (!registroActual?.completadoEntrenamiento) {
        const nuevoRegistro = { ...registroActual };
        const cantidadActividades =
          actividades[registroActual?.ordenSesion - 1]?.length;

        // Actualiza las fechas y el progreso en el entrenamiento
        nuevoRegistro.fechaInicio = new Timestamp(
          nuevoRegistro?.fechaInicio?.seconds,
          nuevoRegistro?.fechaInicio?.nanoseconds
        );
        nuevoRegistro.fechaSesion = Timestamp.now();
        nuevoRegistro.ordenActividad += 1;

        // Verifica si el entrenamiento está completo
        if (
          nuevoRegistro.ordenActividad === cantidadActividades &&
          nuevoRegistro?.ordenSesion === actividades.length
        ) {
          nuevoRegistro.completadoSesion = true;
          nuevoRegistro.completadoEntrenamiento = true;
          return nuevoRegistro;
        }

        // Si se completó la sesión actual, pasa a la siguiente
        if (nuevoRegistro.ordenActividad === cantidadActividades) {
          nuevoRegistro.ordenSesion += 1;
          nuevoRegistro.ordenActividad = 0;
          nuevoRegistro.completadoSesion = false;
        }

        return nuevoRegistro;
      }

      console.log(
        "No se puede calcular el siguiente registro, se retorna el actual."
      );
      return registroActual;
    };

    return esperarActividadesCargadas;
  };


export const getParentPath = (pathname: string) => {
  const segments = pathname.split('/').filter(Boolean);
  segments.pop();
  return `/${segments.join('/')}`;
};

export const countWords = (str: string) => {
  const palabras = str.match(/\b\w+\b/g);
  return palabras ? palabras.length : 0;
}

export const calculatePPM = (palabras: number, tiempoSegundos: number) => {
  if (tiempoSegundos <= 0) return 0; // Evita división por cero
  return (palabras * 60) / tiempoSegundos;
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const dataURLtoBlob = (dataURL) => {
  const byteString = atob(dataURL.split(',')[1]); // Decodificar Base64
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]; // Extraer MIME type
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export const getTodayInString = () => {
  // Generar la fecha en formato DDMMYY
  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Mes empieza en 0
  const year = String(now.getFullYear()).slice(-2);
  return `${day}${month}${year}`;
}